import { RouteGroup, RouteNavSchema } from "./common";

export const newsRoutes = new RouteGroup({ prefix: "news" })
  .addRoute({
    path: "index",
    name: "news-index",
    component: () => import("~/pages/news")
  })
  .addRoute({
    path: ":year/:month/:day/:slug",
    name: "news-year-month-day-slug",
    component: () => import("~/pages/news/_slug")
  });

export const newsNav = new RouteNavSchema({ icon: "mdi-newspaper", label: "Статьи" }).addChild({
  label: "Список",
  to: { name: "news-index" }
});
