import { profileRoutes, profileNav } from "./profile";
import { usersNav, usersRoutes } from "./users";
import { recommendationsNav, recommendationsRoute } from "./recommendations";
import { expertsNav, expertsRoute } from "./experts";
import { investCompaniesNav, investCompainesRoute } from "./invest-companies";
import { salesNav, salesRoutes } from "./sales";
import { promocodesNav, promocodesRoutes } from "./promocodes";
import { productsRoutes, productsNav } from "./products";
import { settingsRoutes, settingsNav } from "./settings";
import { indicesNav, indicesRoutes } from "./indices";
import { stocksNav, stocksRoutes } from "./stocks";
import { compilationsNav, compilationsRoutes } from "./compilations";
import { currenciesNav, currenciesRoutes } from "~/router/routes/currencies";
import { cryptoNav, cryptoRoutes } from "~/router/routes/crypto";
import { countriesNav, countryRoute } from "./countries";
import { mediaNav, mediaRoute } from "./media";
import { compareCompilationsNav, compareCompilationsRoutes } from "./compare-compilations";
import { definitionsNav, definitionsRoutes } from "./definitions";
import { newsNav, newsRoutes } from "./news";
import { logsNav, logsRoutes } from "./logs";
import { mobileNav, mobileRoutes } from "./mobile";

export const panelRoutes = [
  { name: "panel-index", path: "", redirect: { name: "profile-index" } },
  ...usersRoutes.getRoutes(),
  ...stocksRoutes.getRoutes(),
  ...recommendationsRoute.getRoutes(),
  ...expertsRoute.getRoutes(),
  ...investCompainesRoute.getRoutes(),
  ...promocodesRoutes.getRoutes(),
  ...salesRoutes.getRoutes(),
  ...indicesRoutes.getRoutes(),
  ...compilationsRoutes.getRoutes(),
  ...currenciesRoutes.getRoutes(),
  ...cryptoRoutes.getRoutes(),
  ...countryRoute.getRoutes(),
  ...compareCompilationsRoutes.getRoutes(),
  ...definitionsRoutes.getRoutes(),
  ...newsRoutes.getRoutes(),
  ...productsRoutes.getRoutes(),
  ...mediaRoute.getRoutes(),
  ...mobileRoutes.getRoutes(),
  ...profileRoutes.getRoutes(),
  ...logsRoutes.getRoutes(),
  ...settingsRoutes.getRoutes()
];

export const mainNavigation = [
  usersNav.getSchema(),
  stocksNav.getSchema(),
  recommendationsNav.getSchema(),
  expertsNav.getSchema(),
  investCompaniesNav.getSchema(),
  newsNav.getSchema(),
  compilationsNav.getSchema(),
  compareCompilationsNav.getSchema(),
  indicesNav.getSchema(),
  currenciesNav.getSchema(),
  cryptoNav.getSchema(),
  countriesNav.getSchema(),
  definitionsNav.getSchema(),
  promocodesNav.getSchema(),
  salesNav.getSchema(),
  productsNav.getSchema(),
  mediaNav.getSchema(),
  mobileNav.getSchema(),
  profileNav.getSchema(),
  logsNav.getSchema(),
  settingsNav.getSchema()
];
